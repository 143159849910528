.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.active {
  /* color: white; */
  color: rgb(255, 74, 167);


}


/* Đây là CSS để ẩn ảnh trên các thiết bị nhỏ như iPad và điện thoại */
@media only screen and (max-width: 1024px) {
  .hide-on-mobile {
    display: none;
  }
}



.active-icon path:first-child {
  fill: #ffffff;
}

/* background */

.bg-image-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  /* Đảm bảo ảnh nền hiển thị phía sau nội dung */
}


/* cat icon */
.fixed-cat-image {
  position: fixed;
  top: 30%;
  /* Điều chỉnh vị trí theo ý của bạn */
  left: 45px;
  /* Điều chỉnh vị trí theo ý của bạn */
  z-index: 10;
  /* Chỉ định thứ tự z để ảnh hiển thị trên phần còn lại của trang */
}


.fixed-cat-icon {
  position: absolute;
  height: 500px;
  top: 30%;
  /* Điều chỉnh vị trí theo ý của bạn */
  left: 176px;
  /* Điều chỉnh vị trí theo ý của bạn */
  z-index: 10;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.icon:hover {
  color: rgb(255, 74, 167);
}

.icon:hover path:first-child {
  fill: #ffffff;

}

.icon1:hover path:nth-child(2) {
  fill: #9f91f0;
}

/* all */


/* button */
@import url('https://fonts.googleapis.com/css?family=Space%20Grotesk:700|Space%20Grotesk:400');

:root {
  /*  change this for scaling  */
  --m: 4rem;

  --red: #FF6565;
  --pink: #FF64F9;
  --purple: #6B5FFF;
  --blue: #4D8AFF;
  --green: #5BFF89;
  --yellow: #FFEE55;
  --orange: #FF6D1B;

}



button {
  border: 2px solid transparent;
  position: relative;
  color: #F3F3F3;
  font-family: 'Space Grotesk';
  /* font-size: var(--m); */
  /* border-radius: calc(0.7 * var(--m)); */
  /* padding: calc(0.5 * var(--m)) calc(1 * var(--m)); */

  display: flex;
  justify-content: center;
  cursor: pointer;

  background: linear-gradient(#e280b3, #f73599), linear-gradient(#fc0dcc 50%, rgba(248, 13, 201, 0.6) 80%, rgba(19, 18, 19, 0)), linear-gradient(90deg, var(--orange), var(--yellow), var(--green), var(--blue), var(--purple), var(--pink), var(--red));
  background-origin: border-box;
  background-clip: padding-box, border-box, border-box;
  background-size: 200%;
  /* animation: animate 2s infinite linear; */
}

button::before {
  content: '';
  /* background: linear-gradient(90deg, var(--orange), var(--yellow), var(--green), var(--blue), var(--purple), var(--pink), var(--red)); */

  position: absolute;
  bottom: -20%;
  z-index: -5;
  background-size: 200%;
  animation: animate 2s infinite linear;
  filter: blur(calc(0.8 * var(--m)));
}

button:hover,
button:hover::before {
  animation: animate 0.5s infinite linear;
}

@keyframes animate {
  0% {
    background-position: 0
  }

  100% {
    background-position: 200%
  }
}

@media screen and (max-width: 1000px) {
  :root {
    --m: 2rem;
  }
}


.box-3 {
  background-image: url(../public/1.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* about */

@import url("https://fonts.googleapis.com/css2?family=Marhey:wght@400;500;600;700&family=Quicksand:wght@300;400;500;600;700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Quicksand", sans-serif;
}


.h1 {
  font-family: "Marhey", sans-serif !important;
  background: linear-gradient(270deg, #D42F4A 0%, #F82BBF 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: #0000;
  /* -webkit-box-decoration-break: clone; */
  opacity: 0;
  animation: title 1.1s ease 0.3s 1 normal forwards;
}

.h3 {
  /* font-family: "Marhey", sans-serif;
  font-size: clamp(1.9rem, 2vw, 3rem); */
  opacity: 0;
  animation: title 1.1s ease 0.3s 1 normal forwards;
}

/* .h2 {

  opacity: 0;
  animation: title 1.1s ease 0.3s 1 normal forwards;
} */
.h2 {
  opacity: 0;
  animation-name: fade-in;
  /* Tên của animation */
  animation-duration: 1.1s;
  /* Thời gian thực hiện animation */
  animation-timing-function: ease;
  /* Hàm thời gian của animation */
  animation-delay: 0.3s;
  /* Độ trễ trước khi bắt đầu animation */
  animation-iteration-count: 1;
  /* Số lần lặp (1 là chỉ chạy một lần) */
  animation-fill-mode: forwards;
  /* Chế độ fill sau khi hoàn thành animation */
}

@keyframes fade-in {
  from {
    opacity: 0;
    /* Trạng thái ban đầu của opacity */
  }

  to {
    opacity: 1;
    /* Trạng thái kết thúc của opacity */
  }
}

@keyframes title {
  0% {
    opacity: 0;
    letter-spacing: 10px;
    color: #ebeeff;
  }

  100% {
    opacity: 1;
    letter-spacing: 0;
    color: #fff;
  }
}

.hidden {
  display: none;
}



.popup-container {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  /* width: min(900px, 90%); */

  background: rgba(133, 133, 133, 0.2);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  /* backdrop-filter: blur(10px); */
  /* -webkit-backdrop-filter: blur(10px); */
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 12px;
  color: #fff;
  z-index: 30;
  transition: all 0.5s ease-in-out;
}


.popup-body {
  color: #f7f7f7;
  /* height: 70vh; */
  /* overflow-y: auto; */
  /* overflow-x: hidden; */
  /* padding: 30px 50px; */
}




.about-container {
  /* display: grid; */
  /* height: 500px; */

  /* gap: 30px; */
  place-items: center;
}

.about-container .h1 {
  margin-bottom: 20px;
  /* line-height: 1.4; */
}

.about-container p {
  line-height: 1.6;
  /* font-size: 1.1rem; */
}

.about-container .img-frame {
  overflow: hidden;

  border-radius: 8px;
  box-shadow: rgb(226, 128, 179) 5px 10px 30px 8px,
    rgb(226, 128, 179) 0px 0px 0px 2px;
}

.about-container .img-frame img {
  aspect-ratio: 6/7;
  object-fit: cover;
  transition: transform 1s;
}

.about-container .img-frame:hover img {
  transform: rotate(4deg) scale(1.25);
}

/* project */

.card {
  isolation: isolate;
}

.media-object {
  --border-width: 1px;
  --radius: 24px;
  display: grid;
  position: relative;
  border-radius: var(--radius);
  border: var(--border-width) solid transparent;
}

.media-object::before {
  content: " ";
  position: absolute;
  inset: calc(var(--border-width) * -1);
  z-index: -1;
  border: inherit;
  border-radius: inherit;
  background-image: conic-gradient(from var(--angle), #381D6A 80%, #E0D1FF 88%, #E0D1FF 92%, #381D6A 100%);
  background-origin: border-box;
  -webkit-mask:
    linear-gradient(black, black) content-box,
    linear-gradient(black, black);
  mask: linear-gradient(black, black),
    linear-gradient(black, black);
  -webkit-mask-clip: content-box, border-box;
  mask-clip: content-box, border-box;
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  animation: spin 3s linear infinite;
}

@supports not (background: paint(something)) {
  .media-object::before {
    background-image: conic-gradient(#381D6A 80%, #E0D1FF 88%, #E0D1FF 92%, #381D6A 100%);
  }
}

.media-object:hover::before {
  animation-play-state: paused;
}

@property --angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}

@keyframes spin {
  to {
    --angle: 1turn;
  }
}


html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

@media (prefers-reduced-motion) {
  html {
    scroll-behavior: none;
  }
}

html *,
html *::after,
html *::before {
  box-sizing: inherit;
}



.card {
  --text-color: #fff;
  --text-color-2: #F3F8FA;
  --bg-color: #0F0620;
  --border-color: #381D6A;

  display: flex;
  flex-direction: column;
  gap: 24px;
  /* width: 844px; */
  /* padding: 32px; */
  border-radius: 24px;
  color: var(--text-color);
  background: var(--bg-color);
  border: 1px solid var(--border-color);
  font: 100%/1.6 'Open Sans', sans-serif;
}

.card__title {
  margin-block: 0;
  font-family: 'Sora', sans-serif;
  font-size: 33px;
  font-weight: 700;
  line-height: 44px;
}

mark {
  background: linear-gradient(270deg, #D42F4A 0%, #F82BBF 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: #0000;
  -webkit-box-decoration-break: clone;
}

.card__description {
  color: var(--text-color-2);
  line-height: 24px;
  margin-block: 0;
}

.card__actions {
  display: flex;
  gap: 24px;
}

.card__button {
  display: block;
  padding: 16px 32px;
  border: 1px solid var(--border-color);
  border-radius: 24px;
  text-decoration: none;
  color: inherit;
  flex-grow: 1;
  text-align: center;
  font-weight: 600;
  line-height: 16px;
}

.media-object {
  /* display: flex; */
  /* justify-content: space-between; */
  /* align-items: center; */
  padding: 24px;
  gap: 24px;
}



.media-object__button {
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* width: fit-content; */
  gap: 8px;
  /* padding: 10px 20px 10px; */
  /* border-radius: 28px; */
  background: linear-gradient(270deg, #E8488A 0%, #D5304B 100%);
  color: #fff;
  text-decoration: none;
  font-family: 'Sora', sans-serif;
  /* font-weight: 600; */
  /* line-height: 16px; */
  text-align: center;
}

/* .media-object__thumbnail { */
/* width: 280px; */
/* height: 160px; */
/* border-radius: 16px; */
/* background-image: url("../public/7.png"); */
/* } */


.source-link {
  position: fixed;
  right: 32px;
  bottom: 32px;
  width: fit-content;
  box-sizing: border-box;
  font-size: 16px;
  display: flex;
  padding: 0.75em 1em 0.75em 0.75em;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  border-radius: 128px;
  background: linear-gradient(270deg, #AFE8EF 0%, #E7E1FA 100%);
  color: #000;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
}

.source-link svg {
  display: block;
  width: 1.5em;
  height: 1.5em;
  border-radius: 0.25em;
}

.source-link.source-link--second {
  right: auto;
  left: 32px;
}

/* contact */

.form-container {
  /* width: 400px; */
  background: rgba(133, 133, 133, 0.2);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 12px;
  /* background: linear-gradient(#becafd, #b9b0ff) padding-box,
    linear-gradient(145deg, transparent 35%, #bcdbfc, #40c9ff) border-box; */
  border: 2px solid transparent;
  padding: 32px 24px;
  font-size: 14px;
  font-family: inherit;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
  border-radius: 16px;
}

.form-container button:active {
  scale: 0.95;
}

.form-container .form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-container .form-group {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.form-container .form-group label {
  display: block;
  margin-bottom: 5px;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
}

.form-container .form-group input {
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  color: #fff;
  font-family: inherit;
  background-color: transparent;
  border: 1px solid #414141;
}

.form-container .form-group textarea {
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  resize: none;
  color: #fff;
  height: 150px;
  border: 1px solid #414141;
  background-color: transparent;
  font-family: inherit;
}

.form-container .form-group input::placeholder {
  opacity: 0.5;
}

.form-container .form-group input:focus {
  outline: none;
  border-color: #eb2d95;
}

.form-container .form-group textarea:focus {
  outline: none;
  border-color: #eb2d95;
}

.form-container .form-submit-btn {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-self: flex-start;
  font-family: inherit;
  /* color: #717171; */
  font-weight: 600;
  width: 40%;
  /* background: #6080cd; */
  border: 1px solid #414141;
  padding: 8px 10px;
  font-size: inherit;
  gap: 8px;
  margin-top: 8px;
  cursor: pointer;
  border-radius: 10px;
}

.form-container .form-submit-btn:hover {
  background-color: #fff;
  border-color: #fff;
}